import logo_a from './imgs/gallary-1.jpg';
import logo_b from './imgs/gallary-2.jpg';
import logo_c from './imgs/gallary-3.jpg';
import logo_d from './imgs/gallary-4.jpg';

import logo_e from './imgs/gallary-5.jpg';
import logo_f from './imgs/gallary-6.jpg';
import logo_g from './imgs/gallary-7.jpg';
import logo_h from './imgs/gallary-8.jpg';

import logo_i from './imgs/gallary-9.jpg';
import logo_j from './imgs/gallary-10.jpg';
import logo_k from './imgs/gallary-11.jpg';
import logo_l from './imgs/gallary-12.jpg';


import evento_a from './imgs/gallary-20.jpg';
import evento_b from './imgs/gallary-21.jpg';
import evento_c from './imgs/gallary-22.jpg';
import evento_d from './imgs/gallary-23.jpg';

import evento_e from './imgs/gallary-24.jpg';
import evento_f from './imgs/gallary-25.jpg';
import evento_g from './imgs/gallary-26.jpg';
import evento_h from './imgs/gallary-27.jpg';






import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';



function App() {
  return (
    <div className="App">



<div class="container">
<nav class="navbar navbar-expand-lg bg-body-tertiary rounded" aria-label="Thirteenth navbar example">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample11" aria-controls="navbarsExample11" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse d-lg-flex" id="navbarsExample11">
          <a class="navbar-brand col-lg-3 me-0" href="#">V. Citara</a>
          <ul class="navbar-nav col-lg-6 justify-content-lg-center">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Inicio</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Clientes</a>
            </li>
            <li class="nav-item">
              <a class="nav-link disabled" aria-disabled="true">Contactenos</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Categorias</a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Comida</a></li>
                <li><a class="dropdown-item" href="#">Accesorios</a></li>
                <li><a class="dropdown-item" href="#">Farmacia</a></li>
              </ul>
            </li>
          </ul>
          <div class="d-lg-flex col-lg-3 justify-content-lg-end">
          <input class="form-control" type="search" placeholder="Search" aria-label="Buscar"></input>
            <button class="btn btn-warning">Buscar</button>
          </div>
        </div>
      </div>
    </nav>
</div>


<hr></hr>

<header id="home" class="header">
        <div class="overlay text-white text-center">
            <h1 class="display-2 font-weight-bold my-3">Huehuetoca Vende...</h1>
            <h2 class="display-4 mb-5">HUEHUETOCA Edo. Mex.</h2>
            <a class="btn btn-lg btn-primary" href="#gallary">Nuestros productos</a>
        </div>
    </header>

    <hr></hr>

    <div id="about" class="container-fluid wow fadeIn" data-wow-duration="1.5s">
        <div class="row">
            <div class="col-lg-6 has-img-bg"></div>
            <div class="col-lg-6">
                <div class="row justify-content-center">
                    <div class="col-sm-8 py-5 my-5">
                        <h2 class="mb-4">Nuestras Categorias:</h2>
                        <p>Comida Rapida</p>
                        <p><b>Farmacias...</b></p>
                        <p>Rosticerias...<a href=''> Pide a Domicilio...</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr></hr>

    <div id="gallary" class="text-center bg-dark text-light has-height-md middle-items wow fadeIn">
        <h2 class="section-title">Conoce nuestros Asociados Diamante...</h2>
    </div>
    <hr></hr>
    <div class="gallary row">

    <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
      <img src={logo_a}  width="340" height="200" class="gallary-img" />
      <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
    </div>

    <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
      <img src={logo_b}  width="340" height="200" class="gallary-img" />
      <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
    </div>

    <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
      <img src={logo_c}  width="340" height="200" class="gallary-img" />
      <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
    </div>

    <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
      <img src={logo_d}  width="340" height="200" class="gallary-img" />
      <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
    </div>

    </div>






    <hr></hr>
    <div class="gallary row">

    <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
      <img src={logo_e}  width="340" height="200" class="gallary-img" />
      <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
    </div>

    <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
      <img src={logo_f}  width="340" height="200" class="gallary-img" />
      <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
    </div>

    <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
      <img src={logo_g}  width="340" height="200" class="gallary-img" />
      <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
    </div>

    <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
      <img src={logo_h}  width="340" height="200" class="gallary-img" />
      <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
    </div>

    </div>




    <hr></hr>
    <div class="gallary row">

    <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
      <img src={logo_i}  width="340" height="200" class="gallary-img" />
      <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
    </div>

    <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
      <img src={logo_j}  width="340" height="200" class="gallary-img" />
      <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
    </div>

    <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
      <img src={logo_k}  width="340" height="200" class="gallary-img" />
      <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
    </div>

    <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
      <img src={logo_l}  width="340" height="200" class="gallary-img" />
      <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
    </div>

    </div>




    <div id="gallary" class="text-center bg-dark text-light has-height-md middle-items wow fadeIn">
        <h2 class="section-title">Zona de Eventos...</h2>
    </div>
    <hr></hr>
    <div class="gallary row">

    <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
      <img src={evento_a}  width="340" height="200" class="gallary-img" />
      <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
    </div>

    <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
      <img src={evento_b}  width="340" height="200" class="gallary-img" />
      <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
    </div>

    <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
      <img src={evento_c}  width="340" height="200" class="gallary-img" />
      <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
    </div>

    <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
      <img src={evento_d}  width="340" height="200" class="gallary-img" />
      <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
    </div>

    </div>

    <hr></hr>

    <div class="gallary row">

<div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
  <img src={evento_e}  width="340" height="200" class="gallary-img" />
  <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
</div>

<div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
  <img src={evento_f}  width="340" height="200" class="gallary-img" />
  <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
</div>

<div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
  <img src={evento_g}  width="340" height="200" class="gallary-img" />
  <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
</div>

<div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
  <img src={evento_h}  width="340" height="200" class="gallary-img" />
  <a href="#" class="gallary-overlay"><i class="gallary-icon ti-plus"></i></a>
</div>

</div>

    


    <hr></hr>



    </div>
  );
}

export default App;
